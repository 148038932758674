import React from 'react';
import './swiper.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import PropTypes from 'prop-types'
import tw from 'twin.macro';
import 'swiper/swiper.min.css';
import './nav.css';
import { useState } from 'react';
import MaytronicsLogo from '../svg/MaytronicsLogo';
import StarIcon from '../svg/StarIcon';
export const SwiperComponent = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

SwiperCore.use([Navigation]);
  const handleSlideChange = (swiper) => {
    // setActiveIndex(swiper.realIndex);
    setActiveIndex(swiper.activeIndex);
  };
  const slides = data.map((item, index) => {
    const isActive = index === activeIndex;
  
    return (
      <SwiperSlide key={`slide-${index}`} 
      className='my-swiper-slide'
      style={{ width: '100%', boxSizing: 'border-box' }}>
        <div css={tw`transition-all h-full flex flex-col justify-center items-center`}  >
      <div className='design' css={tw`w-full p-3 md:px-0 lg:px-3 flex gap-3 flex-col justify-between  text-center bg-cover bg-center relative`} 
        style={{
          boxSizing: 'border-box',
          borderRadius: '20px',
          boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 1)',
          overflow: `auto`,
          transition: 'height 0.3s',
          position: 'relative',
          backgroundImage: `url(${item.background})`,
          zIndex: 1,
        }}
      >
        <div className='half-background' css={tw`absolute w-full h-1/2 bottom-0 left-0`} style={{ background: '#58C8DF' }}></div>
<div className='card-body' css={tw`flex-grow bg-gray-100 text-black flex flex-col overflow-hidden justify-between pb-5 px-5 md:px-3 lg:px-5 mt-6 mx-4`}>
  <div css={tw`h-full flex flex-col text-sm md:text-xs lg:text-sm font-black leading-tight overflow-hidden mb-5 mt-3`}>
    <div css={tw`flex justify-center items-center my-3`}>
      <StarIcon className="rating" css={tw`h-5 opacity-100`}/>
      <StarIcon className="rating" css={tw`h-5 opacity-100`}/>
      <StarIcon className="rating" css={tw`h-5 opacity-100`}/>
      <StarIcon className="rating" css={tw`h-5 opacity-100`}/>
      <StarIcon className="rating" css={tw`h-5 opacity-100`}/>
    </div>
    <div css={tw`flex-grow overflow-auto`}>
      <p css={tw`h-full font-bold`}>{item.text}</p>
    </div>
  </div>
  <div css={tw`w-full text-center`}>
    <div className='person-name footer-text' css={tw`font-display mx-auto px-3 text-sm truncate text-white`} style={{fontWeight:"900"}}>{item.footer}</div>
  </div>
</div>      
      <div css={tw`opacity-0 py-2`}>,</div>
      <div css={tw`w-full flex justify-center items-center z-10 -mt-8 mb-2`}>
      <div css={tw`w-28`}><MaytronicsLogo fill={"#ffff"}/></div>
      </div>
  </div>
  </div>
</SwiperSlide>
    );
  });

  return (
    <Swiper
  centeredSlides={true}
  loop={true}
  onSlideChange={handleSlideChange}
  style={{ width: '100%', height: '100%', overflow: 'visible' }}
  breakpoints={{
    0: {
      slidesPerView: 1,
      spaceBetween: -5
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 7
    }
  }}
  navigation
  speed={1000}
>
      {slides}
    </Swiper>
  );
};

SwiperComponent.propTypes = {
	data: PropTypes.objectOf(PropTypes.array),
	}

