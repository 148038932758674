import React from 'react'
const EliteLogo = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 373.85 213.63"
		fill="currentColor"
		{...props}
	>
		<path
			d="M297.7,66.37c-.76-.6-1.55-1.17-2.37-1.7a36.13,36.13,0,0,0-20-5.58h0c-11.22,0-21.35,3.57-28.94,9.88-8.5,7.06-13.83,17.56-13.83,30.52a44,44,0,0,0,.36,5.45c.06.49.14,1,.21,1.44.21,1.35.48,2.66.81,3.94.11.41.2.83.33,1.23a37.61,37.61,0,0,0,4,9.16c.17.28.35.55.52.82a38.87,38.87,0,0,0,2.57,3.5l.36.45a38.42,38.42,0,0,0,7.54,6.74l.47.31a41.11,41.11,0,0,0,4.33,2.52l0,0h0a47.38,47.38,0,0,0,21.19,4.82c12.37,0,24.89-5.55,32.49-15.22l-16.64-12.2h0c-.5.67-1,1.32-1.55,2-3.76,4.36-8.61,7.39-15.4,7.39-9.19,0-16-5.86-17.91-14.89h55.14V99.49C311.43,84.83,306.42,73.31,297.7,66.37ZM256.29,90.78a15.76,15.76,0,0,1,5.17-10.44,17,17,0,0,1,11.63-4.14,14.25,14.25,0,0,1,14.58,14.58Zm-96-40.24A13.73,13.73,0,0,0,169,26.17a13.66,13.66,0,0,0-8.73-3.2A13.76,13.76,0,0,0,146.5,36.75a13.91,13.91,0,0,0,13.78,13.79ZM116.39,138h23.77V18.21H116.39Zm94.75-99.82H187.37V61h-39v77h23.76V80h15.21v36.61c0,.06,0,.11,0,.16a30.14,30.14,0,0,0,.19,3.22c0,.36.1.7.15,1.05.11.71.23,1.42.39,2.09.09.35.18.69.28,1,.18.65.4,1.29.64,1.91.11.27.21.55.33.82a17.87,17.87,0,0,0,1.15,2.22c0,.06.07.14.11.2a18.41,18.41,0,0,0,1.64,2.24l.27.28a18,18,0,0,0,1.64,1.63l.47.4a20.48,20.48,0,0,0,1.76,1.3l.48.31a21.57,21.57,0,0,0,2.27,1.25l.12.07h0a38.08,38.08,0,0,0,16.14,3.1c5.54,0,11.41-.48,16.64-2.38v-.08h0V117.72h0c-1.55,1.42-4.79,2-7.38,2.14-.86.06-1.65.08-2.29.08-10.29,0-11.25-6-11.25-14.26V80h20.92V61H211.14Z"
			fill={props.color?props.color:"#dcddde"}
		/>
		<path
			d="M64.12,36.42c-22.45,0-41.53,11.73-49.67,30.74a53.87,53.87,0,0,0-4.21,21.29c0,.25,0,.5,0,.76,0,1.73.09,3.43.26,5.09.06.59.18,1.14.25,1.72.18,1.33.36,2.65.62,3.93.11.52.26,1,.39,1.52.31,1.31.63,2.61,1,3.87.13.4.29.78.43,1.18.47,1.34,1,2.65,1.54,3.93.12.27.26.53.39.8.64,1.37,1.33,2.71,2.09,4,.09.16.2.31.29.46a47.35,47.35,0,0,0,2.69,4,1.33,1.33,0,0,0,.14.19c1,1.36,2.12,2.65,3.27,3.89l0,0c9.85,10.52,24.24,16.65,40.41,16.65,13.83,0,26.85-4.34,37.65-15.11h0a62.89,62.89,0,0,0,4.25-4.7L88.28,107.23A44.53,44.53,0,0,1,82.67,113a30.81,30.81,0,0,1-19.32,7.53c-.57,0-1.11.11-1.7.11a30.17,30.17,0,0,1-3.46-.22c-8.86-1-16.29-5.76-20.26-13.46A28.38,28.38,0,0,1,35,97.12h75.57V90.3C110.58,55.82,91.79,36.42,64.12,36.42ZM37.74,68.84a23.39,23.39,0,0,1,7.94-9.47A25.7,25.7,0,0,1,60.62,55C73.42,55,82,61.27,84.78,71.59a27.72,27.72,0,0,1,1,6.94H35A33.22,33.22,0,0,1,37.74,68.84Z"
			fill="#59c8df"
		/>
		<path
			d="M209.19,40.12H189.32V62.94h-39v30.6q10.05-.3,19.86-.81V78.06h19.11V91.51c6.74-.49,13.38-1.05,19.87-1.7V78.06h20.92V62.94H209.19ZM275.3,61c-18.77,0-33.42,10.2-38.68,25.54q10.23-1.41,19.87-3c2.79-5.16,8.29-9.29,16.6-9.29a16.58,16.58,0,0,1,10.76,3.92q9.54-2.16,18.23-4.55C296.17,65.57,287,61,275.3,61ZM64.12,38.36c-29.68,0-51.3,20.52-51.87,48.93a847.47,847.47,0,0,0,96.38,6.6V90.3C108.63,58.26,91.58,38.36,64.12,38.36ZM32.79,80.48l.29-2.19c2.06-15.81,12.36-25.24,27.54-25.24,16.51,0,26.92,9.75,27.13,25.46l0,2Zm127.49-31.9a11.84,11.84,0,1,0-11.83-11.83A11.85,11.85,0,0,0,160.28,48.58ZM118.35,94c1.15,0,2.29,0,3.45,0,5.52,0,11-.07,16.41-.17V20.16H118.35Z"
			fill="#ffffff"
			opacity="0.3"
		/>
		<polygon
			points="337.52 33.68 345.58 50.02 363.61 52.64 350.57 65.36 353.64 83.31 337.52 74.83 321.39 83.31 324.47 65.36 311.43 52.64 329.46 50.02 337.52 33.68"
			fill="#59c8df"
		/>
		<path
			d="M352.91,53.06l-8.63-1.26-6.76-13.72L330.75,51.8,315.61,54l11,10.67-.19,1.14A144.06,144.06,0,0,0,352.91,53.06Z"
			fill="#ffffff"
			opacity="0.32"
		/>
	</svg>
)
export default EliteLogo
