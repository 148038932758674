import React from 'react'
export const MineralSwimPhBalancerModule = (props) => (
	<svg
	version="1.1"
	id="Layer_1"
	xmlns="http://www.w3.org/2000/svg"
	xmlnsXlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 907.09 595.28"
	style={{ enableBackground: 'new 0 0 907.09 595.28' }}
	xmlSpace="preserve"
	{...props}
>
	<style>{`.st00 {
    fill: #434a52;
  }
  svg:hover .st00 {
		fill: #58C8DF;
	}.st0{fill:##434a52;}.st1{fill:#FFFFFF;}`}</style>
	<g>
		<g>
    <path class="st00" d="M416.7,216.06c0-19.81-2.26-34.48-23.53-34.48h-18.64c-3.58,0-5.65,1.78-5.65,5.15v64.2h24.28
			C414.45,250.93,416.7,235.87,416.7,216.06 M571.82,268.77h-24.85v-61.03h-68.52v61.03H453.6V130.06h24.85v56.87h68.52v-56.87
			h24.85V268.77z M439.48,216.06c0,31.7-8.85,52.71-40.85,52.71h-29.74v33.69h-22.02V181.58c0-11.89,5.65-17.83,16.94-17.83h34.83
			C430.63,163.75,439.48,184.35,439.48,216.06 M653.96,174.83L577.14,41.36c-7.55-13.11-25.93-23.74-41.05-23.74H382.59
			c-15.13,0-33.51,10.63-41.05,23.74l-76.81,133.46c-7.55,13.11-7.55,34.37,0,47.48l76.81,133.46
			c7.55,13.11,25.93,23.74,41.05,23.74h153.49c15.13,0,33.51-10.62,41.05-23.74l76.81-133.46
			C661.5,209.2,661.5,187.94,653.96,174.83"/>
		</g>
		<g>
    <path class="st0" d="M797.84,539.63v-3.47c0-3.51,0.63-4.34,4.22-4.34h13.88v-10.06c-2.84-0.16-9.94-0.38-13.68-0.38
			c-8.91,0-17.01,0.44-17.01,12.34v22.77c0,11.89,8.1,12.42,17.01,12.42c2.88,0,10.65-0.3,13.68-0.46v-10h-13.88
			c-3.59,0-4.22-0.89-4.22-4.43v-4.12h17.29v-10.25H797.84z M779.27,558.42h-13.15c-3.59,0-4.22-0.87-4.22-4.43v-32.51h-12.62v34.99
			c0,11.89,8.12,12.42,17.03,12.42c2.72,0,9.92-0.28,12.97-0.43V558.42z M740.02,521.48h-12.46v31.35c0,4.24-1.46,5.64-5.77,5.64
			c-4.4,0-5.78-1.4-5.78-5.64v-31.35h-12.44v30.83c0,13.44,7.16,16.94,18.22,16.94c11.05,0,18.22-3.51,18.22-16.94V521.48z
			 M671.34,531.69c0.61-0.08,1.36-0.16,2.23-0.16c9.62,0,9.75,5.89,9.75,13.6c0,7.71-0.13,13.62-9.75,13.62
			c-0.87,0-1.62-0.08-2.23-0.16V531.69z M658.72,567.8c4.38,0.89,9.96,1.46,16.11,1.46c17.9,0,21.11-8.05,21.11-24.13
			S692.73,521,674.83,521c-5.94,0-11.85,0.65-16.11,1.54V567.8z M631.43,558.64c-6.5,0-6.56-5.4-6.56-13.52
			c0-8.08,0.06-13.56,6.56-13.56c6.49,0,6.54,5.48,6.54,13.56C637.96,553.24,637.92,558.64,631.43,558.64 M631.43,569.25
			c15,0,19.32-7,19.32-24.13S646.42,521,631.43,521s-19.32,7-19.32,24.13S616.43,569.25,631.43,569.25 M605.22,568.77l-1.74-38.17
			c-0.35-7.41-2.01-9.6-7.51-9.6c-3.47,0-6.54,2.84-8.62,8.22l-6.66,17.11l-6.61-17.27c-2.17-5.66-4.95-8.05-8.46-8.05
			c-5.19,0-7.18,2.94-7.49,9.54l-1.76,38.23h11.75l0.26-28.63l0.04-0.02l5.6,16.06c1.52,4.34,3.47,6.56,6.76,6.56
			c3.25,0,5.31-2.19,6.72-6.56l5.17-16.16h0.04l0.37,28.76H605.22z M509.33,531.35c1.2-0.16,2.54-0.25,3.7-0.25
			c5.19,0,6.7,1.58,6.7,5.32c0,4.32-2.47,5.5-6.82,5.5c-1.19,0-2.37-0.02-3.57-0.16V531.35z M509.33,551.98
			c0.75,0.06,1.64,0.1,2.56,0.13l8.89,16.66h14.27l-10.35-18.45c4.69-2.03,7.51-6.13,7.51-13.86c0-13.27-8.59-15.46-19.42-15.46
			c-6.07,0-11.52,0.57-16.03,1.46v46.31h12.58V551.98z M468.91,539.63v-3.47c0-3.51,0.63-4.34,4.22-4.34h13.89v-10.06
			c-2.85-0.16-9.95-0.38-13.68-0.38c-8.9,0-17,0.44-17,12.34v22.77c0,11.89,8.1,12.42,17,12.42c2.88,0,10.66-0.3,13.68-0.46v-10
			h-13.89c-3.59,0-4.22-0.89-4.22-4.43v-4.12h17.29v-10.25H468.91z M448.85,557.55c-3.29,0.63-7.04,0.95-10.68,0.95
			c-8.54,0-8.83-5.58-8.83-13.37c0-7.75,0.45-13.37,8.83-13.37c3.72,0,7.27,0.34,10.72,0.93l1.12-9.94
			c-3.13-0.98-7.85-1.74-13.41-1.74c-15.97,0-20.01,7.32-20.01,24.13c0,16.78,4.02,24.13,20.01,24.13c5.46,0,10.29-0.69,13.41-1.66
			L448.85,557.55z M397.21,550.99l-10.46-21.74c-2.96-6.17-5.92-8.26-10-8.26c-4.69,0-7.43,2.94-7.43,9.82v37.95h11.42v-29.61
			l10.41,21.81c3.13,6.54,5.56,8.28,10.01,8.28c4.81,0,7.46-2.96,7.46-9.86v-37.91h-11.42V550.99z M337.41,549.08l3.84-14.06
			l3.96,14.06H337.41z M332.06,568.77l2.68-9.82h13.25l2.78,9.82h13.35l-13.23-39.06c-2.01-5.9-4.97-8.71-9.15-8.71
			c-4.4,0-7.08,2.42-9.25,8.71l-13.46,39.06H332.06z M314.81,558.42h-13.15c-3.59,0-4.22-0.87-4.22-4.43v-32.51h-12.62v34.99
			c0,11.89,8.12,12.42,17.03,12.42c2.72,0,9.92-0.28,12.97-0.43V558.42z M252.97,549.08l3.83-14.06l3.96,14.06H252.97z
			 M247.61,568.77l2.68-9.82h13.25l2.78,9.82h13.35l-13.23-39.06c-2.01-5.9-4.97-8.71-9.15-8.71c-4.4,0-7.08,2.42-9.25,8.71
			l-13.46,39.06H247.61z M207.81,531.35c0.98-0.13,2.28-0.2,3.51-0.2c4.3,0,7.11,0.43,7.11,4.28c0,3.82-2.94,4.49-6.78,4.49h-3.84
			V531.35z M207.77,549.37h3.88c4.06,0,7.17,0.67,7.17,4.97c0,3.96-2.92,4.69-6.94,4.69c-1.48,0-2.94-0.1-4.1-0.27V549.37z
			 M211.32,521c-6.09,0-12.48,0.75-16.05,1.52v45.35c4.67,0.87,10.41,1.38,16.76,1.38c10.09,0,19.3-2.17,19.3-13.84
			c0-6.01-3.35-9.38-7.63-10.84c4.6-1.77,6.98-6.09,6.98-9.99C230.68,522.78,220.32,521,211.32,521 M145.02,549.91h11.92v18.85
			h12.68v-47.28h-12.68v17.88h-11.92v-17.88H132.4v47.28h12.62V549.91z M103.05,559.34v-12.38c0-1.62,0.06-3.39,0.22-4.85
			c0.89-0.27,2.21-0.41,3.47-0.41c5.68,0,5.78,4.77,5.78,9.01c0,5-0.3,8.93-6.7,8.93C104.92,559.64,103.86,559.51,103.05,559.34
			 M103.05,568.95c1.5,0.2,3.23,0.3,4.69,0.3c11.34,0,16.68-4.38,16.68-18.55c0-14.31-5.23-18.57-17.67-18.57
			c-5.26,0-11.19,1.15-15.3,3.75c-0.22,2.58-0.3,4.74-0.3,7.39v38.8h11.91V568.95z"/>
		</g>
		<g>
    <path class="st0" d="M718.08,473.76l-0.8-17.49c-0.16-3.39-0.92-4.4-3.44-4.4c-1.59,0-2.99,1.3-3.95,3.76l-3.05,7.84l-3.03-7.91
			c-1-2.59-2.27-3.69-3.87-3.69c-2.38,0-3.29,1.35-3.43,4.37l-0.81,17.51h5.38l0.13-13.12l0.01-0.01l2.57,7.35
			c0.7,1.99,1.59,3.01,3.1,3.01c1.49,0,2.44-1,3.08-3.01l2.37-7.4h0.01l0.17,13.17H718.08z M677.98,456.88h4.86v16.88h5.8v-16.88
			h4.92v-4.78h-15.58V456.88z M673.91,491.46l-1.75-38.17c-0.34-7.41-2.01-9.6-7.51-9.6c-3.47,0-6.53,2.84-8.62,8.22l-6.65,17.11
			l-6.62-17.27c-2.17-5.66-4.95-8.05-8.46-8.05c-5.19,0-7.18,2.94-7.49,9.54l-1.77,38.23h11.75l0.27-28.64l0.04-0.02l5.6,16.06
			c1.52,4.34,3.47,6.55,6.75,6.55c3.25,0,5.32-2.19,6.72-6.55l5.17-16.16h0.04l0.37,28.76H673.91z M617.03,444.18h-12.64v47.28
			h12.64V444.18z M598.09,444.18h-12.46l-4.69,31.17h-0.04l-3.16-16.32c-1.44-7.39-4.45-9.78-8.12-9.78c-3.61,0-6.7,2.33-8.14,9.74
			l-3.21,16.42h-0.04l-4.49-31.23h-12.68l8.34,39.16c1.34,6.27,3.81,8.61,8.09,8.61c4.24,0,7.51-2.5,8.63-8.57l3.2-17.39h0.04
			l3.57,17.39c1.34,6.52,4.2,8.57,8.64,8.57c4.24,0,6.7-2.35,8.06-8.64L598.09,444.18z M517.42,481.54c-3.68,0-9.54-0.67-12.83-1.2
			l-1.24,9.9c3.98,0.89,8.93,1.71,14.73,1.71c11.49,0,18.08-3.92,18.08-14.85c0-8.34-3.02-12.42-13.03-15.12
			c-4.89-1.32-6.96-1.89-6.96-4.75c0-3.19,3.8-3.35,7.17-3.35c3.78,0,7.98,0.67,11.16,1.22l1.16-9.62
			c-3.37-0.83-8.99-1.78-14.61-1.78c-11.22,0-17.46,3.45-17.46,14.24c0,9.07,5.14,12.16,13.64,14.84c4.32,1.36,6.01,2.35,6.01,4.89
			C523.24,480.42,521.52,481.54,517.42,481.54 M483,481.11h-13.15c-3.59,0-4.22-0.87-4.22-4.42v-32.51h-12.62v34.99
			c0,11.89,8.12,12.42,17.03,12.42c2.72,0,9.92-0.29,12.97-0.43V481.11z M421.16,471.78l3.84-14.06l3.96,14.06H421.16z
			 M415.8,491.46l2.68-9.82h13.25l2.78,9.82h13.35l-13.23-39.06c-2.01-5.91-4.97-8.71-9.15-8.71c-4.41,0-7.08,2.42-9.25,8.71
			l-13.46,39.06H415.8z M372.74,454.04c1.2-0.16,2.54-0.24,3.69-0.24c5.2,0,6.7,1.58,6.7,5.32c0,4.32-2.48,5.5-6.82,5.5
			c-1.2,0-2.37-0.02-3.57-0.16V454.04z M372.74,474.68c0.75,0.06,1.64,0.1,2.56,0.12l8.89,16.66h14.27l-10.35-18.45
			c4.69-2.03,7.51-6.13,7.51-13.86c0-13.27-8.59-15.46-19.42-15.46c-6.07,0-11.53,0.57-16.03,1.46v46.31h12.58V474.68z
			 M332.31,462.32v-3.47c0-3.51,0.63-4.34,4.22-4.34h13.88v-10.06c-2.84-0.16-9.94-0.39-13.68-0.39c-8.91,0-17,0.45-17,12.34v22.77
			c0,11.89,8.09,12.42,17,12.42c2.88,0,10.65-0.3,13.68-0.46v-10.01h-13.88c-3.59,0-4.22-0.89-4.22-4.43v-4.12h17.29v-10.24H332.31z
			 M299.05,473.69l-10.46-21.74c-2.96-6.17-5.93-8.26-10.01-8.26c-4.69,0-7.43,2.94-7.43,9.82v37.95h11.43v-29.61L293,483.67
			c3.13,6.54,5.56,8.28,10,8.28c4.81,0,7.47-2.96,7.47-9.87v-37.9h-11.42V473.69z M261.77,444.18h-12.64v47.28h12.64V444.18z
			 M240.91,491.46l-1.74-38.17c-0.35-7.41-2.01-9.6-7.51-9.6c-3.47,0-6.54,2.84-8.62,8.22l-6.66,17.11l-6.61-17.27
			c-2.17-5.66-4.95-8.05-8.46-8.05c-5.19,0-7.18,2.94-7.49,9.54l-1.76,38.23h11.75l0.26-28.64l0.04-0.02l5.6,16.06
			c1.52,4.34,3.47,6.55,6.76,6.55c3.25,0,5.31-2.19,6.72-6.55l5.17-16.16h0.04l0.36,28.76H240.91z"/>
		</g>
    <g>
    <path class="st1" d="M536.09,381.92H382.6c-15.87,0-35.23-11.19-43.15-24.95l-76.81-133.46c-7.92-13.76-7.92-36.14,0-49.89
			l76.81-133.46c7.92-13.76,27.27-24.95,43.15-24.95h153.49c15.87,0,35.23,11.19,43.15,24.95l76.81,133.46
			c7.92,13.75,7.92,36.13,0,49.89l-76.81,133.46C571.32,370.73,551.96,381.92,536.09,381.92z M382.6,20.04
			c-14.33,0-31.81,10.11-38.96,22.53l-76.81,133.46c-7.15,12.42-7.15,32.64,0,45.07l76.81,133.46
			c7.15,12.43,24.63,22.53,38.96,22.53h153.49c14.33,0,31.81-10.11,38.96-22.53l76.81-133.46c7.15-12.43,7.15-32.65,0-45.07
			L575.05,42.57c-7.15-12.42-24.63-22.53-38.96-22.53H382.6z"/>
    </g>
	</g>
</svg>
)

export default MineralSwimPhBalancerModule
