import React from 'react'
export const MaytronicsLogo = (props) => (
	<svg id="Layer_1" 
	data-name="Layer 1" 
	xmlns="http://www.w3.org/2000/svg" 
	viewBox="0 0 404.29 97.5">
		<style>{`.cls-1{fill:${props.fill};}`}</style>

	<path class="cls-1" d="M25,51.61c0-2.8-.41-8.74-.41-10.71h4.94c.08,2.8.16,5.93.33,6.67h.24a14.25,14.25,0,0,1,13-7.66c5,0,9.64,2.47,11.45,8.24,2.39-5.69,7.66-8.24,12.94-8.24,11.86,0,14.5,8.07,14.5,16.39V79h-5V56.55c0-6.18-1.73-12.19-9.55-12.19C58.4,44.36,56,52.1,56,57.54V79H51.07V56.06c0-7-2.55-11.7-7.91-11.7C35.83,44.36,30,49.46,30,59.93V79H25Z" transform="translate(-24.63 -22.11)"/>
	<path class="cls-1" d="M121.5,63a116.26,116.26,0,0,0,.74,16h-4.61a36.83,36.83,0,0,1-.5-6.18H117c-2.8,4.86-6.92,7.17-13.35,7.17S90.19,76.73,90.19,68.66c0-11.62,13.6-12.77,21.09-12.77h5.77V53.58c0-6.59-4.61-9.22-10.71-9.22a17.17,17.17,0,0,0-11.61,4.28l-2.89-3.38c3.63-3.46,9.56-5.35,14.5-5.35,9.23,0,15.16,4.12,15.16,13.76Zm-9.89-3.13c-8.23,0-16.47,2.06-16.47,8.56,0,4.7,4.53,7.09,8.65,7.09,10.54,0,13.26-6.76,13.26-13.1V59.85Z" transform="translate(-24.63 -22.11)"/>
	<path class="cls-1" d="M143.74,90.74c-1.9,4.86-5,8-10.46,8a19.31,19.31,0,0,1-4.86-.74l.49-4.62a16.17,16.17,0,0,0,4.45.91c3.62,0,5.19-3.38,6.34-6.34l3.46-8.82L127.67,40.9h5.52l12.61,32,12.27-32h5.28Z" transform="translate(-24.63 -22.11)"/>
	<path class="cls-1" d="M191.85,45.35H181V70.14c0,3.38,2,5.36,5.28,5.36a14.72,14.72,0,0,0,5.6-1.32l.41,4.45A21.73,21.73,0,0,1,185.83,80c-7.08,0-9.8-4.37-9.8-9.81V45.35h-8.24V40.9H176v-11H181v11h10.88Z" transform="translate(-24.63 -22.11)"/>
	<path class="cls-1" d="M202.31,51.61c0-2.8-.42-8.74-.42-10.71h5c.08,2.8.16,5.93.33,6.67,2.47-4.45,6.42-7.66,12.11-7.66a11.44,11.44,0,0,1,2.88.41l-.58,4.86a6,6,0,0,0-1.89-.33c-8.57,0-12.44,5.93-12.44,13.35V79h-4.94Z" transform="translate(-24.63 -22.11)"/>
	<path class="cls-1" d="M305.52,51.61c0-2.8-.41-8.74-.41-10.71h4.94c.08,2.8.17,5.93.33,6.67h.25a14.24,14.24,0,0,1,13-7.66c11.87,0,14.5,8.07,14.5,16.39V79H333.2V56.55c0-6.18-1.73-12.19-9.56-12.19-7.33,0-13.18,5.1-13.18,15.57V79h-4.94Z" transform="translate(-24.63 -22.11)"/>
	<path class="cls-1" d="M349.67,22.11a3.71,3.71,0,1,1-3.7,3.71A3.7,3.7,0,0,1,349.67,22.11ZM347.2,40.9h4.95V79H347.2Z" transform="translate(-24.63 -22.11)"/>
	<path class="cls-1" d="M394.73,73.36a17.66,17.66,0,0,1-14,6.59c-11.7,0-20-8.41-20-20s8.32-20,20-20A18.66,18.66,0,0,1,394.9,46.5l-4,3c-2.88-3.13-6.26-5.1-10.21-5.1-9.31,0-15.08,7-15.08,15.57s5.77,15.57,15.08,15.57a13.22,13.22,0,0,0,10.21-5.27Z" transform="translate(-24.63 -22.11)"/>
	<path class="cls-1" d="M404.2,69.24c2.06,4,6.59,6.26,11,6.26,4.29,0,8.73-2.64,8.73-7C424,58,401.4,65.37,401.4,50.87c0-7.42,6.76-11,13-11s11,2.31,13.43,7.17l-4.45,2.63a9.31,9.31,0,0,0-9-5.35c-3.54,0-8.08,2.14-8.08,6.09,0,10.3,22.58,2.31,22.58,17.72C428.92,76,422.41,80,415.16,80c-6.51,0-12.36-2.47-15.32-7.75Z" transform="translate(-24.63 -22.11)"/>
	<path class="cls-1" d="M259.38,39.53h0c-20.72.07-37.52,18.3-37.68,40.84a52.72,52.72,0,0,1,5.88,2.43,66.09,66.09,0,0,1,6.36,3.46,31.67,31.67,0,0,1-.5-5.56c0-15.6,11.62-28.3,25.94-28.37S285.31,65.1,285.31,80.7a55.59,55.59,0,0,1-.72,7.37c5.37-1.19,10.83-5.47,12.46-7.7C296.89,57.83,280.09,39.6,259.38,39.53Z" transform="translate(-24.63 -22.11)"/>
	<path class="cls-1" d="M294.25,89.66A45.77,45.77,0,0,1,260,101.54a52.68,52.68,0,0,1-21.24-6.24c-1.46-.82-3.45-1.92-4.45-2.55s-1.95-1.31-2.95-1.88a63.58,63.58,0,0,0-6.08-3.25A48,48,0,0,0,199,83.33a72.55,72.55,0,0,0-30.73,11.75v0a63.61,63.61,0,0,1,29-4.59,43.94,43.94,0,0,1,22.41,8.71,58.12,58.12,0,0,1,4.81,4c.79.69,1.52,1.5,2.29,2.21s2.34,2.09,3.49,3.08A48.49,48.49,0,0,0,247.91,118a40.34,40.34,0,0,0,32.08-4,39.24,39.24,0,0,0,14-14.11c4.92-8.83,3.93-14.09,4-14s0,0,0,0A52,52,0,0,1,294.25,89.66Z" transform="translate(-24.63 -22.11)"/>
	</svg>
)

export default MaytronicsLogo
