import React from 'react'
export const MineralSwimGenerator = (props) => (
	<svg
	version="1.1"
	id="Layer_1"
	xmlns="http://www.w3.org/2000/svg"
	xmlnsXlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 595.28 595.28"
	style={{ enableBackground: 'new 0 0 595.28 595.28' }}
	xmlSpace="preserve"
	{...props}
>
	<style>{`.st00 {
    fill: #434a52;
  }
  svg:hover .st00 {
		fill: #58C8DF;
	}.st0{fill:##434a52;}.st1{fill:#FFFFFF;}`}</style>
	<g>
		<g>
		<path class="st00" d="M366.08,272h-23.29V132.65h23.29V272z M327.66,272h-58.13c-47.78,0-59.13-29.86-59.13-69.68
			c0-39.82,11.35-69.68,59.13-69.68h55.94v20.9h-43.6c-33.84,0-44.39,10.95-44.39,48.78s10.55,48.77,44.39,48.77h45.79V272z
			 M493.48,178.47L416.31,44.38c-7.58-13.17-26.05-23.85-41.25-23.85H220.84c-15.2,0-33.66,10.68-41.25,23.85l-77.17,134.09
			c-7.58,13.17-7.58,34.53,0,47.7l77.17,134.09c7.58,13.17,26.05,23.85,41.25,23.85h154.22c15.2,0,33.66-10.67,41.25-23.85
			l77.17-134.09C501.06,213,501.06,191.64,493.48,178.47"/>
		</g>
		<g>
		<path class="st0" d="M465.01,536.66c1.2-0.16,2.55-0.24,3.71-0.24c5.22,0,6.73,1.59,6.73,5.34c0,4.34-2.49,5.52-6.85,5.52
			c-1.2,0-2.38-0.02-3.59-0.16V536.66z M465.01,557.4c0.75,0.06,1.65,0.1,2.57,0.12l8.94,16.74h14.33l-10.4-18.53
			c4.71-2.04,7.54-6.16,7.54-13.93c0-13.33-8.63-15.54-19.51-15.54c-6.1,0-11.58,0.57-16.11,1.47v46.53h12.64V557.4z M424.93,564.09
			c-6.52,0-6.59-5.42-6.59-13.58c0-8.12,0.06-13.63,6.59-13.63c6.53,0,6.57,5.51,6.57,13.63
			C431.5,558.66,431.46,564.09,424.93,564.09 M424.93,574.75c15.07,0,19.41-7.03,19.41-24.24c0-17.21-4.34-24.25-19.41-24.25
			c-15.07,0-19.41,7.03-19.41,24.25C405.52,567.72,409.86,574.75,424.93,574.75 M366.45,537.23h10.66v37.03h12.72v-37.03h10.79
			v-10.48h-34.17V537.23z M340.88,554.49l3.85-14.13l3.98,14.13H340.88z M335.5,574.26l2.69-9.87h13.32l2.8,9.87h13.41l-13.29-39.25
			c-2.02-5.93-5-8.75-9.19-8.75c-4.43,0-7.12,2.43-9.3,8.75l-13.51,39.25H335.5z M292.23,536.66c1.2-0.16,2.55-0.24,3.71-0.24
			c5.22,0,6.73,1.59,6.73,5.34c0,4.34-2.49,5.52-6.85,5.52c-1.2,0-2.39-0.02-3.59-0.16V536.66z M292.23,557.4
			c0.76,0.06,1.66,0.1,2.57,0.12l8.93,16.74h14.33l-10.39-18.53c4.71-2.04,7.54-6.16,7.54-13.93c0-13.33-8.62-15.54-19.51-15.54
			c-6.1,0-11.58,0.57-16.11,1.47v46.53h12.64V557.4z M251.61,544.98v-3.49c0-3.53,0.64-4.36,4.24-4.36h13.95v-10.11
			c-2.85-0.17-9.99-0.39-13.74-0.39c-8.95,0-17.08,0.45-17.08,12.4v22.88c0,11.95,8.13,12.48,17.08,12.48
			c2.89,0,10.7-0.31,13.74-0.47v-10.05h-13.95c-3.61,0-4.24-0.9-4.24-4.45v-4.14h17.37v-10.3H251.61z M218.19,556.4l-10.5-21.83
			c-2.98-6.2-5.96-8.3-10.05-8.3c-4.71,0-7.46,2.96-7.46,9.87v38.13h11.48v-29.75l10.46,21.92c3.14,6.56,5.59,8.32,10.05,8.32
			c4.83,0,7.5-2.98,7.5-9.91v-38.09h-11.48V556.4z M162.14,544.98v-3.49c0-3.53,0.63-4.36,4.24-4.36h13.95v-10.11
			c-2.85-0.17-9.99-0.39-13.74-0.39c-8.95,0-17.09,0.45-17.09,12.4v22.88c0,11.95,8.14,12.48,17.09,12.48
			c2.89,0,10.7-0.31,13.74-0.47v-10.05h-13.95c-3.61,0-4.24-0.9-4.24-4.45v-4.14h17.37v-10.3H162.14z M123.16,556.28h6.89v3.87
			c0,3.06-0.67,4.04-4.14,4.04c-8.34,0-8.79-5.61-8.79-13.68c0-7.89,0.45-13.6,8.87-13.6c4.5,0,9.01,0.22,13.37,0.89l1.1-9.88
			c-5.26-1.18-10.4-1.66-16.07-1.66c-16.02,0-20.1,7.18-20.1,24.25c0,16.86,4.08,24.24,20.1,24.24c9.73,0,16.84-0.51,16.84-10.51
			v-17.84h-18.09V556.28z"/>
		</g>
		<g>
		<path class="st0" d="M561.89,478.81l-0.8-17.57c-0.16-3.41-0.92-4.42-3.46-4.42c-1.6,0-3,1.31-3.97,3.78l-3.06,7.87l-3.04-7.95
			c-1-2.61-2.28-3.71-3.89-3.71c-2.39,0-3.31,1.35-3.45,4.39l-0.81,17.6h5.4l0.13-13.17l0.01-0.01l2.58,7.38
			c0.7,2,1.6,3.02,3.11,3.02c1.5,0,2.45-1.01,3.09-3.02l2.38-7.43h0.01l0.17,13.23H561.89z M521.6,461.85h4.88v16.96h5.83v-16.96
			h4.94v-4.8H521.6V461.85z M517.52,496.59l-1.76-38.35c-0.34-7.44-2.01-9.64-7.54-9.64c-3.49,0-6.56,2.85-8.66,8.26l-6.68,17.19
			l-6.65-17.35c-2.18-5.69-4.98-8.09-8.5-8.09c-5.22,0-7.22,2.96-7.52,9.58l-1.78,38.41h11.81l0.27-28.77l0.04-0.02l5.63,16.13
			c1.53,4.36,3.49,6.59,6.79,6.59c3.26,0,5.34-2.2,6.75-6.59l5.2-16.23h0.04l0.37,28.89H517.52z M460.37,449.09h-12.7v47.5h12.7
			V449.09z M441.34,449.09h-12.52l-4.71,31.31h-0.04l-3.18-16.39c-1.45-7.42-4.47-9.83-8.16-9.83c-3.63,0-6.73,2.34-8.17,9.79
			l-3.22,16.49h-0.04l-4.51-31.38h-12.74l8.38,39.35c1.34,6.3,3.83,8.65,8.13,8.65c4.26,0,7.55-2.51,8.67-8.61l3.22-17.47h0.04
			l3.59,17.47c1.34,6.55,4.22,8.61,8.68,8.61c4.26,0,6.73-2.36,8.1-8.68L441.34,449.09z M360.29,486.63c-3.69,0-9.58-0.67-12.89-1.2
			l-1.24,9.95c4,0.9,8.97,1.71,14.8,1.71c11.54,0,18.17-3.93,18.17-14.92c0-8.38-3.04-12.48-13.09-15.19c-4.91-1.33-7-1.9-7-4.77
			c0-3.2,3.82-3.36,7.2-3.36c3.8,0,8.01,0.67,11.21,1.22l1.17-9.66c-3.38-0.83-9.03-1.79-14.68-1.79
			c-11.28,0-17.54,3.47-17.54,14.31c0,9.12,5.16,12.21,13.7,14.91c4.34,1.36,6.03,2.36,6.03,4.91
			C366.14,485.5,364.41,486.63,360.29,486.63 M325.71,486.19H312.5c-3.61,0-4.24-0.87-4.24-4.44v-32.66h-12.68v35.15
			c0,11.95,8.16,12.48,17.11,12.48c2.73,0,9.97-0.28,13.03-0.43V486.19z M263.58,476.82l3.85-14.13l3.98,14.13H263.58z
			 M258.2,496.59l2.69-9.87h13.31l2.8,9.87h13.42l-13.3-39.25c-2.02-5.94-5-8.75-9.19-8.75c-4.43,0-7.12,2.43-9.3,8.75l-13.52,39.25
			H258.2z M214.93,459c1.2-0.17,2.55-0.25,3.71-0.25c5.22,0,6.73,1.59,6.73,5.34c0,4.34-2.49,5.52-6.85,5.52
			c-1.2,0-2.38-0.02-3.59-0.16V459z M214.93,479.73c0.75,0.06,1.65,0.1,2.57,0.13l8.93,16.74h14.33l-10.4-18.53
			c4.71-2.04,7.54-6.16,7.54-13.93c0-13.33-8.63-15.53-19.51-15.53c-6.1,0-11.58,0.57-16.11,1.47v46.53h12.64V479.73z
			 M174.32,467.32v-3.49c0-3.53,0.63-4.36,4.24-4.36h13.95v-10.11c-2.85-0.16-9.99-0.39-13.74-0.39c-8.95,0-17.08,0.45-17.08,12.4
			v22.88c0,11.95,8.13,12.48,17.08,12.48c2.9,0,10.7-0.31,13.74-0.47v-10.05h-13.95c-3.61,0-4.24-0.89-4.24-4.45v-4.14h17.37v-10.3
			H174.32z M140.9,478.73l-10.5-21.84c-2.98-6.2-5.96-8.3-10.05-8.3c-4.71,0-7.46,2.96-7.46,9.87v38.13h11.48v-29.75l10.46,21.92
			c3.14,6.57,5.59,8.32,10.05,8.32c4.83,0,7.5-2.98,7.5-9.91v-38.09H140.9V478.73z M103.44,449.09h-12.7v47.5h12.7V449.09z
			 M82.48,496.59l-1.75-38.35c-0.35-7.44-2.02-9.64-7.54-9.64c-3.49,0-6.57,2.85-8.66,8.26l-6.69,17.19l-6.65-17.35
			c-2.18-5.69-4.98-8.09-8.5-8.09c-5.22,0-7.22,2.96-7.52,9.58l-1.77,38.41h11.81l0.26-28.77l0.04-0.02l5.63,16.13
			c1.53,4.36,3.49,6.59,6.79,6.59c3.26,0,5.34-2.2,6.75-6.59l5.2-16.23h0.04l0.36,28.89H82.48z"/>
		</g>
		<g>
		<path class="st1" d="M375.06,386.54H220.84c-15.95,0-35.39-11.25-43.35-25.07l-77.17-134.08c-7.95-13.82-7.95-36.31,0-50.12
			l77.17-134.09c7.96-13.82,27.4-25.07,43.35-25.07h154.22c15.95,0,35.39,11.25,43.35,25.07l77.17,134.09
			c7.96,13.82,7.96,36.3,0,50.12l-77.17,134.09C410.45,375.29,391.01,386.54,375.06,386.54z M220.84,22.96
			c-14.4,0-31.96,10.16-39.14,22.64l-77.17,134.09c-7.19,12.48-7.19,32.8,0,45.28l77.17,134.08c7.18,12.48,24.74,22.64,39.14,22.64
			h154.22c14.4,0,31.96-10.16,39.14-22.64l77.17-134.08c7.19-12.49,7.19-32.8,0-45.28L414.2,45.59
			c-7.18-12.48-24.74-22.64-39.14-22.64H220.84z"/>
	</g>
	</g>
</svg>
)

export default MineralSwimGenerator
