import React from 'react'
export const MineralSwimPurifier = (props) => (
	<svg
	version="1.1"
	id="Layer_1"
	xmlns="http://www.w3.org/2000/svg"
	xmlnsXlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 595.28 595.28"
	style={{ enableBackground: 'new 0 0 595.28 595.28' }}
	xmlSpace="preserve"
	{...props}
>
	<style>{`.st00 {
    fill: #434a52;
  }
  svg:hover .st00 {
		fill: #58C8DF;
	}.st0{fill:#434a52;}.st1{fill:#FFFFFF;}`}</style>
	<g>
		<g>
    <path class="st00" d="M311.6,203.92c0-39.76-10.34-50.3-44.53-50.3s-44.33,10.54-44.33,50.3c0,39.76,10.14,50.3,44.33,50.3
			S311.6,243.68,311.6,203.92 M413.69,254.85c0,12.52-3.36,22.37-19.12,22.37h-43.35v-12.17h32.57c9.5,0,14.14-0.23,14.14-11.01
			c0-6.83-1.27-11.7-11.01-11.7h-33.61v-12.17H386c8.69,0,10.78-3.36,10.78-11.12c0-6.03-1.05-10.78-9.51-10.78h-34.65v-12.17h40.68
			c14.37,0,19.24,7.18,19.24,21.32c0,7.42-1.27,16.92-12.17,18.31v0.46C411.03,236.77,413.69,245.7,413.69,254.85 M338.64,203.92
			c0,56.26-19.68,71.17-71.57,71.17c-51.48,0-71.37-14.91-71.37-71.17c0-56.26,19.88-71.17,71.37-71.17
			C318.96,132.75,338.64,147.66,338.64,203.92 M497.7,185.21l-77.06-133.9c-7.57-13.15-26.01-23.82-41.19-23.82h-154
			c-15.18,0-33.62,10.66-41.19,23.82l-77.06,133.9c-7.57,13.16-7.57,34.48,0,47.64l77.06,133.9c7.57,13.16,26.01,23.82,41.19,23.82
			h154c15.18,0,33.62-10.66,41.19-23.82l77.06-133.9C505.27,219.7,505.27,198.37,497.7,185.21"/>
		</g>
		<g>
    <path class="st1" d="M379.44,392.99h-154c-15.92,0-35.34-11.23-43.29-25.04l-77.06-133.9c-7.94-13.8-7.94-36.26,0-50.05
			l77.06-133.9c7.95-13.8,27.37-25.03,43.29-25.03h154c15.92,0,35.35,11.23,43.29,25.03L499.8,184c7.94,13.8,7.94,36.25,0,50.05
			l-77.07,133.9C414.79,381.76,395.37,392.99,379.44,392.99z M225.45,29.92c-14.38,0-31.91,10.14-39.09,22.61l-77.06,133.9
			c-7.18,12.46-7.18,32.75,0,45.21l77.06,133.9c7.17,12.46,24.71,22.6,39.09,22.6h154c14.38,0,31.92-10.14,39.09-22.6l77.07-133.9
			c7.18-12.47,7.18-32.75,0-45.21l-77.07-133.9l0,0c-7.17-12.47-24.71-22.61-39.09-22.61H225.45z"/>
		</g>
    <g>
    <path class="st0" d="M425.11,542.9c1.2-0.16,2.54-0.25,3.71-0.25c5.21,0,6.72,1.59,6.72,5.34c0,4.34-2.48,5.52-6.84,5.52
			c-1.2,0-2.38-0.02-3.58-0.16V542.9z M425.11,563.6c0.75,0.06,1.65,0.1,2.56,0.13l8.92,16.71h14.31l-10.38-18.51
			c4.7-2.04,7.53-6.15,7.53-13.91c0-13.32-8.61-15.51-19.49-15.51c-6.09,0-11.56,0.57-16.08,1.46v46.46h12.62V563.6z M384.55,551.21
			v-3.49c0-3.52,0.63-4.36,4.23-4.36h13.93v-10.1c-2.85-0.16-9.98-0.39-13.73-0.39c-8.93,0-17.06,0.45-17.06,12.38v22.85
			c0,11.93,8.12,12.46,17.06,12.46c2.89,0,10.69-0.31,13.73-0.47v-10.04h-13.93c-3.6,0-4.23-0.89-4.23-4.44v-4.13h17.35v-10.28
			H384.55z M362.75,533h-12.68v47.44h12.68V533z M324.67,580.44v-18.95H342v-10.28h-17.32v-3.49c0-3.52,0.63-4.36,4.21-4.36h13.95
			v-10.1c-2.87-0.08-9.98-0.39-13.74-0.39c-8.92,0-17.06,0.45-17.06,12.38v35.18H324.67z M302.66,533h-12.68v47.44h12.68V533z
			 M257.75,542.9c1.2-0.16,2.54-0.25,3.71-0.25c5.21,0,6.72,1.59,6.72,5.34c0,4.34-2.48,5.52-6.84,5.52c-1.2,0-2.38-0.02-3.58-0.16
			V542.9z M257.75,563.6c0.75,0.06,1.65,0.1,2.56,0.13l8.92,16.71h14.31l-10.38-18.51c4.7-2.04,7.53-6.15,7.53-13.91
			c0-13.32-8.61-15.51-19.49-15.51c-6.09,0-11.56,0.57-16.08,1.46v46.46h12.62V563.6z M235.84,533h-12.5v31.45
			c0,4.26-1.47,5.66-5.78,5.66c-4.42,0-5.81-1.41-5.81-5.66V533h-12.48v30.93c0,13.48,7.18,17,18.28,17c11.08,0,18.28-3.52,18.28-17
			V533z M169.66,542.9c1.2-0.16,2.54-0.25,3.7-0.25c5.22,0,6.72,1.55,6.72,5.54c0,4.52-2.49,5.7-6.84,5.7
			c-1.2,0-2.38-0.02-3.58-0.16V542.9z M157.04,580.44h12.62v-16.51c1.12,0.08,2.61,0.14,3.87,0.14c11.24,0,19.08-2.83,19.08-15.88
			c0-13.54-8.61-15.68-19.49-15.68c-6.09,0-11.57,0.57-16.08,1.46V580.44z"/>
    </g>
    <g>
		<path class="st0" d="M567.23,485.13l-0.8-17.54c-0.16-3.4-0.92-4.41-3.45-4.41c-1.59,0-3,1.3-3.97,3.78l-3.06,7.86l-3.04-7.94
			c-1-2.6-2.27-3.7-3.89-3.7c-2.39,0-3.3,1.35-3.44,4.38l-0.81,17.57h5.4l0.13-13.16l0.01-0.01l2.57,7.37c0.7,2,1.59,3.02,3.11,3.02
			c1.49,0,2.44-1.01,3.09-3.02l2.38-7.42h0.01l0.17,13.21H567.23z M527,468.19h4.88v16.94h5.82v-16.94h4.93v-4.79H527V468.19z
			 M522.92,502.88l-1.75-38.3c-0.34-7.43-2.01-9.63-7.53-9.63c-3.48,0-6.55,2.85-8.65,8.25l-6.67,17.16l-6.64-17.33
			c-2.18-5.68-4.97-8.08-8.49-8.08c-5.21,0-7.21,2.95-7.51,9.57l-1.77,38.36h11.79l0.27-28.73l0.04-0.02l5.62,16.11
			c1.53,4.36,3.48,6.58,6.78,6.58c3.26,0,5.34-2.2,6.74-6.58l5.19-16.21h0.04l0.37,28.85H522.92z M465.85,455.45h-12.68v47.44h12.68
			V455.45z M446.85,455.45h-12.5l-4.7,31.27h-0.04l-3.18-16.37c-1.44-7.41-4.46-9.81-8.14-9.81c-3.63,0-6.72,2.34-8.16,9.77
			l-3.22,16.47h-0.04l-4.5-31.33h-12.72l8.37,39.29c1.34,6.29,3.82,8.63,8.12,8.63c4.26,0,7.54-2.51,8.66-8.59l3.21-17.45h0.04
			l3.58,17.45c1.34,6.54,4.21,8.59,8.67,8.59c4.26,0,6.72-2.36,8.09-8.67L446.85,455.45z M365.92,492.93
			c-3.69,0-9.57-0.67-12.87-1.2l-1.24,9.93c3.99,0.9,8.96,1.71,14.78,1.71c11.53,0,18.14-3.93,18.14-14.9
			c0-8.37-3.03-12.46-13.07-15.17c-4.91-1.32-6.99-1.9-6.99-4.77c0-3.19,3.81-3.36,7.19-3.36c3.79,0,8,0.67,11.2,1.22l1.16-9.65
			c-3.38-0.83-9.02-1.79-14.66-1.79c-11.26,0-17.51,3.46-17.51,14.29c0,9.1,5.15,12.2,13.68,14.89c4.34,1.36,6.03,2.36,6.03,4.91
			C371.76,491.81,370.03,492.93,365.92,492.93 M331.38,492.5h-13.19c-3.61,0-4.24-0.87-4.24-4.44v-32.62h-12.66v35.1
			c0,11.93,8.14,12.46,17.08,12.46c2.72,0,9.96-0.28,13.01-0.43V492.5z M269.34,483.13l3.85-14.11l3.97,14.11H269.34z
			 M263.96,502.88l2.69-9.85h13.29l2.79,9.85h13.4l-13.28-39.19c-2.02-5.93-4.99-8.74-9.18-8.74c-4.42,0-7.11,2.42-9.28,8.74
			l-13.5,39.19H263.96z M220.76,465.34c1.2-0.16,2.54-0.25,3.7-0.25c5.22,0,6.72,1.59,6.72,5.34c0,4.34-2.49,5.52-6.84,5.52
			c-1.2,0-2.38-0.02-3.58-0.16V465.34z M220.76,486.04c0.75,0.06,1.65,0.1,2.56,0.13l8.92,16.71h14.31l-10.38-18.51
			c4.7-2.04,7.53-6.15,7.53-13.91c0-13.32-8.61-15.51-19.49-15.51c-6.09,0-11.57,0.57-16.08,1.46v46.46h12.62V486.04z M180.2,473.65
			v-3.49c0-3.52,0.63-4.36,4.23-4.36h13.93v-10.1c-2.85-0.16-9.98-0.39-13.72-0.39c-8.94,0-17.06,0.45-17.06,12.38v22.85
			c0,11.93,8.12,12.46,17.06,12.46c2.89,0,10.69-0.31,13.72-0.47V492.5h-13.93c-3.6,0-4.23-0.89-4.23-4.44v-4.13h17.34v-10.28H180.2
			z M146.83,485.05l-10.49-21.81c-2.97-6.19-5.95-8.28-10.04-8.28c-4.7,0-7.45,2.95-7.45,9.85v38.07h11.46v-29.7l10.45,21.89
			c3.14,6.56,5.58,8.31,10.03,8.31c4.83,0,7.49-2.97,7.49-9.89v-38.03h-11.46V485.05z M109.42,455.45H96.74v47.44h12.68V455.45z
			 M88.49,502.88l-1.75-38.3c-0.35-7.43-2.02-9.63-7.53-9.63c-3.48,0-6.56,2.85-8.65,8.25l-6.68,17.16l-6.64-17.33
			c-2.18-5.68-4.97-8.08-8.49-8.08c-5.21,0-7.21,2.95-7.51,9.57l-1.77,38.36h11.79l0.26-28.73l0.04-0.02l5.62,16.11
			c1.53,4.36,3.48,6.58,6.78,6.58c3.26,0,5.33-2.2,6.74-6.58l5.19-16.21h0.04l0.36,28.85H88.49z"/>
	</g>
	</g>
</svg>
)

export default MineralSwimPurifier
