import bg1 from '../images/MineralSwimV2_Testimonial_3.jpg'
import bg2 from '../images/MineralSwimV2_Testimonial_Katie_NSW.jpg'
import bg3 from '../images/MineralSwimV2_Testimonial_Well_Nourished.jpg'
import bg4 from '../images/MineralSwimV2_Web_Existing-Pool.jpg'
import bg5 from '../images/TemporaryImageEliteBuilder.jpg'
export const testimonials = [
  {
    "text":"Just when I thought my pool could not get any better, I converted it from saltwater to a Mineral Swim system and hallelujah! I‘m so happy I did! My pool is definitely cleaner and clearer, and it feels softer on my skin. Now that I have a Mineral Swim pool, I‘m convinced this is the way pools are meant to be and I‘m sure that in the future, mineral systems like this will become the new standard.",
    "footer":"Lana, Three Birds Renovations",
    "background":bg1
  },
  {
    "text":"We love it! Everyone should have this. I would highly recommend Mineral Swim.",
    "footer":"Katie, Mineral Swim Pool Owner, NSW",
    "background":bg2
  },
  {
    "text":" I’m so happy to have found a pool system that allows us to swim in our pool without exposing ourselves to harmful chemicals",
    "footer":"Georgia Harding",
    "background":bg3
    },
      {
        "text":"We had our existing pool converted to a mineral pool just before Christmas (along with the installation of an Ozone system). After a few months of swimming, we are still thrilled with the change. I could never go back to a traditional salt water pool.",
        "footer":"Monty Hill",
        "background":bg4
      },
      {
        "text":"I’m so happy with the conversion of my pool with a Mineral Swim system. The results are amazing and has helped my wife psoriasis very much.",
        "footer":"Anthony K",
        "background":bg5
      },
      {
        "text":"Being a tradesman with a physically demanding job, it is nice to come home from work and relax in the 100% Dead Sea minerals.",
        "footer":"Adam",
        "background":bg4
      },
      {
        "text":"We are really pleased we went with the Mineral Swim system. The water looks clear, feels great and there’s no smell of chlorine on your skin after a swim. The Mineral Swim team have been extremely helpful and the customer service we’ve received – second to none!",
        "footer":"Melanie Jayne",
        "background":bg5
      }
]