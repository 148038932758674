import React from 'react'
export const MineralSwimDeadSeaMinerals = (props) => (
	<svg
	version="1.1"
	id="Layer_1"
	xmlns="http://www.w3.org/2000/svg"
	xmlnsXlink="http://www.w3.org/1999/xlink"
	x="0px"
	y="0px"
	viewBox="0 0 907.09 595.28"
	style={{ enableBackground: 'new 0 0 595.28 595.28' }}
	xmlSpace="preserve"
	{...props}
>
	<style>{`.st00 {
    fill: #434a52;
  }
  svg:hover .st00 {
		fill: #58C8DF;
	}.st0{fill:#434a52;}.st1{fill:#FFFFFF;}`}</style>
	<g>
		<g>
    <path class="st00" d="M525.89,237.89h21.03v-47.72c0-2.98-1.49-4.47-4.48-4.47h-16.55c-16.85,0-18.64,11.04-18.64,25.95
			C507.25,226.55,509.04,237.89,525.89,237.89 M564.37,245.2c0,22.37-10.29,32.21-34.15,32.21h-35.5v-13.42h35.5
			c8.95,0,15.51-4.03,16.7-12.68h-25.35c-25.35,0-32.36-15.81-32.36-39.67c0-23.86,7.01-39.37,32.36-39.37h29.38
			c8.95,0,13.42,4.48,13.42,13.42V245.2z M477.17,268.01h-24.95l0.76-108.44c0-3.78-1.51-4.18-2.27-4.18
			c-1.32,0-2.64,2.98-3.21,4.57l-33.83,92.92c-3.97,10.75-7.56,16.71-18.9,16.71c-11.34,0-14.93-5.97-18.9-16.71l-33.83-92.92
			c-0.57-1.59-1.89-4.57-3.22-4.57c-2.08,0-2.08,3.58-2.08,4.18l0.57,108.44h-24.95V145.44c0-12.73,8.13-18.3,21.36-18.3
			c15.12,0,19.65,8.16,23.25,17.91l34.21,92.92c1.32,2.98,2.27,5.17,3.59,5.17s2.27-2.19,3.59-5.17l34.21-92.92
			c3.59-9.75,8.13-17.91,23.25-17.91c13.23,0,21.36,5.57,21.36,18.3V268.01z M633.77,177.22L556.64,43.21
			c-7.58-13.16-26.03-23.84-41.22-23.84H361.29c-15.19,0-33.65,10.67-41.22,23.84l-77.13,134.01c-7.58,13.17-7.58,34.51,0,47.68
			l77.13,134.01c7.58,13.17,26.03,23.84,41.22,23.84h154.13c15.19,0,33.65-10.67,41.22-23.84l77.13-134.01
			C641.35,211.73,641.35,190.39,633.77,177.22"/>
		</g>
		<g>
    <path class="st0" d="M862.11,563.28c-3.67,0-9.54-0.68-12.83-1.21l-1.24,9.98c3.98,0.9,8.93,1.72,14.74,1.72
			c11.49,0,18.09-3.94,18.09-14.97c0-8.41-3.03-12.52-13.03-15.24c-4.9-1.33-6.96-1.9-6.96-4.79c0-3.21,3.79-3.37,7.17-3.37
			c3.77,0,7.97,0.68,11.16,1.23l1.16-9.7c-3.37-0.84-8.99-1.8-14.62-1.8c-11.23,0-17.46,3.48-17.46,14.36
			c0,9.15,5.13,12.25,13.64,14.96c4.33,1.37,6.01,2.37,6.01,4.93C867.94,562.16,866.21,563.28,862.11,563.28 M842.7,562.85h-13.15
			c-3.6,0-4.22-0.88-4.22-4.46v-32.77H812.7v35.26c0,11.98,8.12,12.52,17.03,12.52c2.72,0,9.93-0.28,12.97-0.43V562.85z
			 M780.84,553.44l3.84-14.18l3.96,14.18H780.84z M775.48,573.28l2.68-9.9h13.25l2.78,9.9h13.36l-13.24-39.37
			c-2.01-5.96-4.97-8.78-9.15-8.78c-4.41,0-7.09,2.43-9.26,8.78l-13.46,39.37H775.48z M732.41,535.56c1.2-0.17,2.54-0.25,3.69-0.25
			c5.2,0,6.7,1.6,6.7,5.36c0,4.36-2.48,5.54-6.82,5.54c-1.2,0-2.37-0.02-3.57-0.16V535.56z M732.41,556.36
			c0.75,0.06,1.64,0.1,2.56,0.13l8.89,16.79h14.27l-10.35-18.59c4.69-2.05,7.51-6.17,7.51-13.97c0-13.38-8.59-15.58-19.43-15.58
			c-6.07,0-11.53,0.57-16.04,1.47v46.68h12.59V556.36z M691.96,543.91v-3.5c0-3.54,0.63-4.38,4.22-4.38h13.89v-10.14
			c-2.84-0.16-9.95-0.39-13.68-0.39c-8.91,0-17.01,0.45-17.01,12.44v22.95c0,11.98,8.1,12.52,17.01,12.52
			c2.89,0,10.66-0.31,13.68-0.47v-10.09h-13.89c-3.59,0-4.22-0.9-4.22-4.46v-4.15h17.3v-10.33H691.96z M658.69,555.36l-10.46-21.91
			c-2.96-6.22-5.93-8.32-10.01-8.32c-4.69,0-7.43,2.97-7.43,9.9v38.25h11.43v-29.84l10.42,21.99c3.13,6.59,5.56,8.35,10,8.35
			c4.81,0,7.47-2.99,7.47-9.94v-38.21h-11.43V555.36z M621.4,525.62h-12.64v47.66h12.64V525.62z M600.53,573.28l-1.74-38.48
			c-0.35-7.47-2.01-9.67-7.51-9.67c-3.48,0-6.54,2.86-8.63,8.28l-6.66,17.24l-6.62-17.41c-2.17-5.71-4.95-8.12-8.47-8.12
			c-5.2,0-7.19,2.97-7.49,9.61l-1.76,38.54h11.75l0.26-28.86l0.04-0.02l5.6,16.18c1.52,4.38,3.47,6.61,6.76,6.61
			c3.25,0,5.32-2.21,6.72-6.61l5.18-16.28H588l0.37,28.99H600.53z M504.66,553.44l3.84-14.18l3.96,14.18H504.66z M499.3,573.28
			l2.68-9.9h13.26l2.78,9.9h13.35l-13.23-39.37c-2.01-5.96-4.97-8.78-9.16-8.78c-4.4,0-7.08,2.43-9.26,8.78l-13.46,39.37H499.3z
			 M462.11,543.91v-3.5c0-3.54,0.63-4.38,4.22-4.38h13.89v-10.14c-2.84-0.16-9.95-0.39-13.68-0.39c-8.91,0-17.01,0.45-17.01,12.44
			v22.95c0,11.98,8.1,12.52,17.01,12.52c2.89,0,10.66-0.31,13.68-0.47v-10.09h-13.89c-3.59,0-4.22-0.9-4.22-4.46v-4.15h17.29v-10.33
			H462.11z M423.5,563.28c-3.68,0-9.54-0.68-12.83-1.21l-1.24,9.98c3.98,0.9,8.93,1.72,14.74,1.72c11.49,0,18.09-3.94,18.09-14.97
			c0-8.41-3.03-12.52-13.03-15.24c-4.9-1.33-6.97-1.9-6.97-4.79c0-3.21,3.8-3.37,7.17-3.37c3.77,0,7.97,0.68,11.16,1.23l1.16-9.7
			c-3.37-0.84-8.99-1.8-14.62-1.8c-11.23,0-17.46,3.48-17.46,14.36c0,9.15,5.13,12.25,13.64,14.96c4.33,1.37,6.01,2.37,6.01,4.93
			C429.33,562.16,427.6,563.28,423.5,563.28 M362.59,535.91c0.61-0.08,1.36-0.17,2.24-0.17c9.62,0,9.74,5.93,9.74,13.71
			c0,7.77-0.12,13.73-9.74,13.73c-0.87,0-1.63-0.08-2.24-0.17V535.91z M349.97,572.3c4.38,0.9,9.97,1.47,16.12,1.47
			c17.91,0,21.11-8.12,21.11-24.32s-3.2-24.32-21.11-24.32c-5.95,0-11.86,0.65-16.12,1.55V572.3z M318.14,553.44l3.84-14.18
			l3.96,14.18H318.14z M312.78,573.28l2.68-9.9h13.26l2.78,9.9h13.36l-13.23-39.37c-2.01-5.96-4.97-8.78-9.16-8.78
			c-4.4,0-7.09,2.43-9.26,8.78l-13.46,39.37H312.78z M275.58,543.91v-3.5c0-3.54,0.63-4.38,4.22-4.38h13.89v-10.14
			c-2.84-0.16-9.95-0.39-13.68-0.39c-8.91,0-17.01,0.45-17.01,12.44v22.95c0,11.98,8.1,12.52,17.01,12.52
			c2.89,0,10.66-0.31,13.68-0.47v-10.09H279.8c-3.59,0-4.22-0.9-4.22-4.46v-4.15h17.29v-10.33H275.58z M230.74,535.91
			c0.61-0.08,1.36-0.17,2.23-0.17c9.62,0,9.75,5.93,9.75,13.71c0,7.77-0.13,13.73-9.75,13.73c-0.87,0-1.62-0.08-2.23-0.17V535.91z
			 M218.11,572.3c4.38,0.9,9.97,1.47,16.11,1.47c17.91,0,21.12-8.12,21.12-24.32s-3.21-24.32-21.12-24.32
			c-5.94,0-11.85,0.65-16.11,1.55V572.3z M184.7,524.64h-7.76l-27.77,49.62h7.75L184.7,524.64z M183.94,567.13
			c-2.8,0-2.82-3.09-2.82-7.86c0-4.79,0.02-7.9,2.82-7.9c2.8,0,2.82,3.11,2.82,7.9C186.76,564.04,186.76,567.13,183.94,567.13
			 M183.94,573.53c9.05,0,10.9-4.7,10.9-14.26c0-9.57-1.85-14.28-10.9-14.28c-9.03,0-10.88,4.7-10.88,14.28
			C173.06,568.83,174.91,573.53,183.94,573.53 M149.92,547.51c-2.82,0-2.82-3.09-2.82-7.86s0.02-7.9,2.82-7.9
			c2.78,0,2.82,3.13,2.82,7.9S152.72,547.51,149.92,547.51 M149.92,553.91c9.03,0,10.88-4.7,10.88-14.25
			c0-9.57-1.85-14.28-10.88-14.28c-9.05,0-10.9,4.7-10.9,14.28C139.02,549.21,140.86,553.91,149.92,553.91 M115.65,563.61
			c-5.28,0-5.3-5.3-5.3-13.44s0.04-13.46,5.3-13.46c5.26,0,5.3,5.32,5.3,13.46S120.93,563.61,115.65,563.61 M115.65,573.77
			c14.64,0,17.64-7.77,17.64-23.6s-3.01-23.6-17.64-23.6s-17.64,7.77-17.64,23.6S101.01,573.77,115.65,573.77 M73.68,563.61
			c-5.28,0-5.3-5.3-5.3-13.44s0.04-13.46,5.3-13.46c5.26,0,5.3,5.32,5.3,13.46S78.96,563.61,73.68,563.61 M73.68,573.77
			c14.64,0,17.64-7.77,17.64-23.6s-3-23.6-17.64-23.6c-14.64,0-17.64,7.77-17.64,23.6S59.05,573.77,73.68,573.77 M48.14,573.28
			v-46.22H38.8c-2.88,1.35-6.92,2.76-12.59,3.62l0.53,10.04c3.53-0.28,7.55-0.88,9.18-1.27v33.83H48.14z"/>
		</g>
		<g>
    <path class="st0" d="M718.31,477.52l-0.8-17.62c-0.16-3.42-0.92-4.43-3.44-4.43c-1.59,0-2.99,1.31-3.95,3.79l-3.05,7.9l-3.03-7.97
			c-1-2.62-2.27-3.72-3.88-3.72c-2.38,0-3.29,1.36-3.43,4.4l-0.81,17.65h5.38l0.12-13.22l0.02-0.01l2.57,7.41
			c0.7,2.01,1.59,3.03,3.1,3.03c1.49,0,2.44-1.01,3.08-3.03l2.37-7.46h0.01l0.17,13.28H718.31z M678.19,460.51h4.86v17.02h5.8
			v-17.02h4.92v-4.82h-15.59V460.51z M674.13,495.36l-1.75-38.48c-0.34-7.47-2.01-9.67-7.51-9.67c-3.47,0-6.53,2.86-8.63,8.28
			l-6.66,17.24l-6.62-17.41c-2.17-5.71-4.95-8.12-8.46-8.12c-5.2,0-7.19,2.97-7.49,9.61l-1.77,38.54H637l0.27-28.86l0.04-0.02
			l5.61,16.18c1.52,4.38,3.47,6.61,6.76,6.61c3.25,0,5.32-2.21,6.72-6.61l5.18-16.28h0.04l0.37,28.99H674.13z M617.22,447.71h-12.64
			v47.66h12.64V447.71z M598.28,447.71h-12.46l-4.69,31.42h-0.04l-3.17-16.45c-1.44-7.45-4.45-9.86-8.12-9.86
			c-3.62,0-6.7,2.35-8.14,9.82l-3.21,16.55h-0.04l-4.49-31.48h-12.69l8.35,39.48c1.34,6.32,3.81,8.67,8.1,8.67
			c4.24,0,7.52-2.52,8.63-8.63l3.2-17.53h0.04l3.57,17.53c1.34,6.57,4.2,8.63,8.65,8.63c4.24,0,6.7-2.37,8.06-8.71L598.28,447.71z
			 M517.59,485.37c-3.68,0-9.54-0.68-12.83-1.21l-1.24,9.98c3.98,0.9,8.93,1.72,14.74,1.72c11.49,0,18.09-3.94,18.09-14.97
			c0-8.41-3.03-12.52-13.04-15.24c-4.89-1.33-6.96-1.9-6.96-4.79c0-3.21,3.8-3.37,7.17-3.37c3.78,0,7.98,0.68,11.16,1.23l1.16-9.7
			c-3.37-0.84-8.99-1.8-14.62-1.8c-11.23,0-17.46,3.48-17.46,14.36c0,9.15,5.14,12.25,13.64,14.96c4.33,1.37,6.01,2.37,6.01,4.93
			C523.41,484.24,521.68,485.37,517.59,485.37 M483.15,484.93H470c-3.6,0-4.22-0.88-4.22-4.46v-32.77h-12.62v35.26
			c0,11.98,8.12,12.52,17.03,12.52c2.72,0,9.93-0.28,12.97-0.43V484.93z M421.29,475.52l3.84-14.18l3.96,14.18H421.29z
			 M415.93,495.36l2.68-9.9h13.25l2.78,9.9h13.36l-13.24-39.37c-2.01-5.96-4.97-8.78-9.15-8.78c-4.41,0-7.09,2.43-9.26,8.78
			l-13.46,39.37H415.93z M372.86,457.65c1.2-0.17,2.54-0.25,3.69-0.25c5.2,0,6.7,1.6,6.7,5.36c0,4.36-2.48,5.54-6.82,5.54
			c-1.2,0-2.37-0.02-3.57-0.16V457.65z M372.86,478.45c0.75,0.06,1.64,0.1,2.56,0.13l8.89,16.79h14.27l-10.35-18.59
			c4.69-2.05,7.51-6.17,7.51-13.97c0-13.38-8.59-15.58-19.43-15.58c-6.07,0-11.53,0.57-16.04,1.47v46.68h12.59V478.45z
			 M332.42,465.99v-3.5c0-3.54,0.63-4.38,4.22-4.38h13.89v-10.14c-2.84-0.16-9.95-0.39-13.68-0.39c-8.91,0-17.01,0.45-17.01,12.44
			v22.95c0,11.98,8.1,12.52,17.01,12.52c2.89,0,10.66-0.31,13.68-0.47v-10.09h-13.89c-3.59,0-4.22-0.9-4.22-4.46v-4.15h17.29v-10.33
			H332.42z M299.14,477.45l-10.46-21.91c-2.96-6.22-5.93-8.32-10.01-8.32c-4.69,0-7.43,2.97-7.43,9.9v38.25h11.43v-29.84
			l10.42,21.99c3.13,6.59,5.56,8.35,10,8.35c4.81,0,7.47-2.99,7.47-9.94v-38.21h-11.43V477.45z M261.85,447.71H249.2v47.66h12.64
			V447.71z M240.98,495.36l-1.74-38.48c-0.35-7.47-2.01-9.67-7.51-9.67c-3.47,0-6.54,2.86-8.63,8.28l-6.66,17.24l-6.62-17.41
			c-2.17-5.71-4.95-8.12-8.47-8.12c-5.2,0-7.19,2.97-7.49,9.61l-1.76,38.54h11.75l0.26-28.86l0.04-0.02l5.6,16.18
			c1.52,4.38,3.47,6.61,6.76,6.61c3.25,0,5.32-2.21,6.72-6.61l5.18-16.28h0.04l0.36,28.99H240.98z"/>
		</g>
    <g>
    <path class="st1" d="M515.42,385.17H361.29c-15.94,0-35.37-11.24-43.33-25.06l-77.13-134.01c-7.95-13.81-7.95-36.29,0-50.1
			L317.97,42c7.95-13.82,27.39-25.06,43.33-25.06h154.13c15.94,0,35.37,11.24,43.33,25.06l0,0l77.13,134.01
			c7.95,13.81,7.95,36.28,0,50.1l-77.13,134.01C550.79,373.93,531.36,385.17,515.42,385.17z M361.29,21.79
			c-14.39,0-31.94,10.15-39.12,22.62l-77.13,134.01c-7.18,12.47-7.18,32.78,0,45.26l77.13,134.01
			c7.18,12.47,24.73,22.62,39.12,22.62h154.13c14.39,0,31.94-10.15,39.12-22.62l77.13-134.01c7.18-12.48,7.18-32.78,0-45.26
			L554.54,44.42c-7.18-12.48-24.73-22.62-39.12-22.62H361.29z"/>
    </g>
	</g>
</svg>
)

export default MineralSwimDeadSeaMinerals
